exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---templates-blog-index-tsx-content-file-path-content-blog-cctv-actuality-index-mdx": () => import("./../../../templates/blog/index.tsx?__contentFilePath=C:/projects/rozetka54/content/blog/cctv-actuality/index.mdx" /* webpackChunkName: "component---templates-blog-index-tsx-content-file-path-content-blog-cctv-actuality-index-mdx" */),
  "component---templates-blog-index-tsx-content-file-path-content-blog-electrical-connection-index-mdx": () => import("./../../../templates/blog/index.tsx?__contentFilePath=C:/projects/rozetka54/content/blog/electrical-connection/index.mdx" /* webpackChunkName: "component---templates-blog-index-tsx-content-file-path-content-blog-electrical-connection-index-mdx" */),
  "component---templates-blog-index-tsx-content-file-path-content-blog-internet-importance-index-mdx": () => import("./../../../templates/blog/index.tsx?__contentFilePath=C:/projects/rozetka54/content/blog/internet-importance/index.mdx" /* webpackChunkName: "component---templates-blog-index-tsx-content-file-path-content-blog-internet-importance-index-mdx" */),
  "component---templates-service-index-tsx": () => import("./../../../templates/service/index.tsx" /* webpackChunkName: "component---templates-service-index-tsx" */)
}

